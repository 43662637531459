<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-input(v-model="listQuery.keyword" clearable style="width: 200px;" placeholder="订单ID/用户/邮箱").filter-item.ml-5
            el-date-picker(v-model="listQuery.date" type="daterange"  value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期").filter-item.ml-5
            el-button(v-permission="['order:exception-order:list']" type="primary" icon="el-icon-search" @click="onFilter").filter-item.ml-5 查找
            el-button(v-permission="['order:exception-order:export']" type="primary" icon="el-icon-upload2" @click="onExport" :loading="downloadLoading").filter-item.float-right 导出Excel

        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="right" label="创建时间" prop="active_date" sortable width="180px")
                template(slot-scope="scope")
                    span {{scope.row.active_date | formatDateAndTime}}
            el-table-column(align="right" label="Amazon订单ID" prop="order_id" sortable width="180px")
            el-table-column(align="right" label="姓名" prop="name" sortable)
            el-table-column(align="right" label="邮箱" prop="email" sortable)
            el-table-column(align="right" label="来源" prop="hash_code" sortable)

        //- 分页
        pagination(v-show="total>0" :total="total"  :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" )
        
</template>

<script>
import * as order from "../../api/order";
import { parseTime } from "../../utils";
import _ from "lodash";
import Pagination from "@/components/pagination"; // Secondary package based on el-pagination

export default {
  name: "exception-order-list",
  components: { Pagination },
  data: () => ({
    list: null,
    total: 0,
    downloadLoading: false,
    listLoading: true,
    listQuery: {
      page: 1,
      limit: 20,
      keyword: undefined,
      date: undefined,
      sort: "creation_date",
      order: "desc"
    },
    current_odr_id: 0
  }),
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.listLoading = true;
      try {
        const resp = await order.getExceptionOrderList(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    },
    onFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    async onExport() {
      this.downloadLoading = true;
      const critria = _.assign(this.listQuery, { limit: 10000, page: 1 });
      const resp = await order.exportExceptionOrderList(critria);
      const list = resp.data.data.items;
      import("@/vendor/Export2Excel").then(excel => {
        const tHeader = ["创建时间", "订单ID", "姓名", "邮箱", "来源"];
        const filterVal = [
          "active_date",
          "order_id",
          "name",
          "email",
          "hash_code"
        ];
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename:
            "异常订单_" + parseTime(new Date(), "{y}-{m}-{d}_{h}:{i}:{s}"),
          autoWidth: true,
          bookType: "xlsx"
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          if (j === "active_date") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    }
  }
};
</script>
